import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import styles from "../../components/MatchHistory/MatchHistory.module.css"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import Icon from '../../components/Utilities/Icon'
import ModalStandard from '../../components/Utilities/ModalStandard'
import Match from "../../components/MatchHistory/Match"
import MatchTotals from "../../components/MatchHistory/MatchTotals"
import Filter from "../../components/Utilities/Filter"
import uniqueVals from "../../js/uniqueVals"
import findTotal from "../../js/findTotal"


class MessiMatchHistory extends React.Component {

  state = {
    ratingsInfoModalActive: false,
    moreFiltersActive: false,
    compFilter: '',
    seasonFilter: '',
    yearFilter: '',
    teamFilter: '',
    goalsFilter: '',
    assistsFilter: '',
    pensFilter: '',
    pensMissedFilter: '',
    freeKicksFilter: '',
    insideBoxFilter: '',
    outsideBoxFilter: '',
    leftFootFilter: '',
    rightFootFilter: '',
    headersFilter: '',
    otherFilter: '',
    shotsFilter: '',
    shotsOnTargetFilter: '',
    successfulDribblesFilter: '',
    keyPassesFilter: '',
    throughballsFilter: '',
    aerialDuelsFilter: '',
    bigChancesCreatedFilter: '',
    xGFilter: '',
    motmFilter: '',
    ratingFilter: '',
    homeAwayFilter: '',
    roundFilter: '',
    legsFilter: '',
    opponentFilter: '',
    vsPlayerFilter: false,
    rankingFromFilter: '',
    rankingToFilter: '',
    uclRankingFromFilter: '',
    uclRankingToFilter: '',
    leagueRankingFromFilter: '',
    leagueRankingToFilter: '',
    dateFromDayFilter: '',
    dateFromMonthFilter: '',
    dateFromYearFilter: '',
    dateToDayFilter: '',
    dateToMonthFilter: '',
    dateToYearFilter: '',
    firstXFilter: '',
    startsFilter: '',
    dayFilter: '',
  };

  baseState = this.state

  resetFilters = () => {
    this.setState(this.baseState)
  }

  toggleMoreFilters = () => {
    this.setState(state => ({ moreFiltersActive: !state.moreFiltersActive }));
  };

  toggleRatingsInfoModal = () => {
    this.setState(state => ({ ratingsInfoModalActive: !state.ratingsInfoModalActive }));
  };

  manageVsPlayerFilter = () => {
    this.setState(state => ({ vsPlayerFilter: !state.vsPlayerFilter }));
  };

  manageCompFilter = (e) => {
    this.setState({compFilter: e.target.value });
    this.setState({uclRankingFromFilter: '' });
    this.setState({uclRankingToFilter: '' });
    this.setState({leagueRankingFromFilter: e.target.value !== "La Liga" && e.target.value !== "Ligue 1" && e.target.value !== "MLS" && e.target.value !== "All League" ? '' : this.state.leagueRankingFromFilter });
    this.setState({leagueRankingToFilter: e.target.value !== "La Liga" && e.target.value !== "Ligue 1" && e.target.value !== "MLS" && e.target.value !== "All League" ? '' : this.state.leagueRankingToFilter });
    this.setState({rankingFromFilter: e.target.value === "Champions League" || e.target.value === "La Liga" || e.target.value === "MLS" || e.target.value === "Ligue 1" || e.target.value === "All League" ? '' : this.state.rankingFromFilter });
    this.setState({rankingToFilter: e.target.value === "Champions League" || e.target.value === "La Liga" || e.target.value === "MLS" || e.target.value === "Ligue 1" || e.target.value === "All League" ? '' : this.state.rankingToFilter });
  };
  manageSeasonFilter = (e) => { this.setState({ seasonFilter: e.target.value }); };
  manageYearFilter = (e) => { this.setState({ yearFilter: e.target.value }); };
  manageTeamFilter = (e) => { this.setState({ teamFilter: e.target.value }); };
  manageGoalsFilter = (e) => { this.setState({ goalsFilter: e.target.value }); };
  manageAssistsFilter = (e) => { this.setState({ assistsFilter: e.target.value }); };
  managePensFilter = (e) => { this.setState({ pensFilter: e.target.value }); };
  managePensMissedFilter = (e) => { this.setState({ pensMissedFilter: e.target.value }); };
  manageFreeKicksFilter = (e) => { this.setState({ freeKicksFilter: e.target.value }); };
  manageInsideBoxFilter = (e) => { this.setState({ insideBoxFilter: e.target.value }); };
  manageOutsideBoxFilter = (e) => { this.setState({ outsideBoxFilter: e.target.value }); };
  manageLeftFootFilter = (e) => { this.setState({ leftFootFilter: e.target.value }); };
  manageRightFootFilter = (e) => { this.setState({ rightFootFilter: e.target.value }); };
  manageHeadersFilter = (e) => { this.setState({ headersFilter: e.target.value }); };
  manageOtherFilter = (e) => { this.setState({ otherFilter: e.target.value }); };
  manageShotsFilter = (e) => { this.setState({ shotsFilter: e.target.value }); };
  manageShotsOnTargetFilter = (e) => { this.setState({ shotsOnTargetFilter: e.target.value }); };
  manageSuccessfulDribblesFilter = (e) => { this.setState({ successfulDribblesFilter: e.target.value }); };
  manageKeyPassesFilter = (e) => { this.setState({ keyPassesFilter: e.target.value }); };
  manageThroughballsFilter = (e) => { this.setState({ throughballsFilter: e.target.value }); };
  manageAerialDuelsFilter = (e) => { this.setState({ aerialDuelsFilter: e.target.value }); };
  manageBigChancesCreatedFilter = (e) => { this.setState({ bigChancesCreatedFilter: e.target.value }); };
  manageXGFilter = (e) => { this.setState({ xGFilter: e.target.value }); };
  manageMotmFilter = (e) => { this.setState({ motmFilter: e.target.value }); };
  manageRatingFilter = (e) => { this.setState({ ratingFilter: e.target.value }); };
  manageHomeAwayFilter = (e) => { this.setState({ homeAwayFilter: e.target.value }); };
  manageRoundFilter = (e) => { this.setState({ roundFilter: e.target.value }); };
  manageLegsFilter = (e) => { this.setState({ legsFilter: e.target.value }); };
  manageOpponentFilter = (e) => { this.setState({ opponentFilter: e.target.value }); };
  manageRankingFromFilter = (e) => { this.setState({ rankingFromFilter: e.target.value }); };
  manageRankingToFilter = (e) => { this.setState({ rankingToFilter: e.target.value }); };
  manageUCLRankingFromFilter = (e) => { this.setState({ uclRankingFromFilter: e.target.value }); };
  manageUCLRankingToFilter = (e) => { this.setState({ uclRankingToFilter: e.target.value }); };
  manageLeagueRankingFromFilter = (e) => { this.setState({ leagueRankingFromFilter: e.target.value }); };
  manageLeagueRankingToFilter = (e) => { this.setState({ leagueRankingToFilter: e.target.value }); };
  manageDateFromDayFilter = (e) => { this.setState({ dateFromDayFilter: e.target.value }); };
  manageDateFromMonthFilter = (e) => { this.setState({ dateFromMonthFilter: e.target.value }); };
  manageDateFromYearFilter = (e) => { this.setState({ dateFromYearFilter: e.target.value }); };
  manageDateToDayFilter = (e) => { this.setState({ dateToDayFilter: e.target.value }); };
  manageDateToMonthFilter = (e) => { this.setState({ dateToMonthFilter: e.target.value }); };
  manageDateToYearFilter = (e) => { this.setState({ dateToYearFilter: e.target.value }); };
  manageFirstXFilter = (e) => { this.setState({ firstXFilter: e.target.value }); };
  manageStartsFilter = (e) => { this.setState({ startsFilter: e.target.value }); };
  manageDayFilter = (e) => { this.setState({ dayFilter: e.target.value }); };

  render() {

    let mdata = this.props.data.allSheetMessiMatchHistory.edges.filter(d => d.node.date)

    const careerGamesTotal = findTotal(mdata)

    const seasons = uniqueVals(mdata.map(d => d.node.season))
    const years = uniqueVals(mdata.map(d => d.node.year))
    const teams = uniqueVals(mdata.map(d => d.node.team))
    let competitions = uniqueVals(mdata.map(d => d.node.competition))
    competitions = [...competitions, 'All Competitive Internationals']
    const goals = uniqueVals(mdata.map(d => d.node.goals))
    const assists = uniqueVals(mdata.map(d => d.node.assists))
    const shots = uniqueVals(mdata.filter(d => d.node.shots && d.node.shots !== '-').map(d => d.node.shots))
    const shotsOnTarget = uniqueVals(mdata.filter(d => d.node.shotsOnTarget && d.node.shotsOnTarget !== '-').map(d => d.node.shotsOnTarget))
    const successfulDribbles = uniqueVals(mdata.filter(d => d.node.successfulDribbles && d.node.successfulDribbles !== '-').map(d => d.node.successfulDribbles))
    const keyPasses = uniqueVals(mdata.filter(d => d.node.keyPasses && d.node.keyPasses !== '-').map(d => d.node.keyPasses))
    const throughballs = uniqueVals(mdata.filter(d => d.node.throughballs && d.node.throughballs !== '-').map(d => d.node.throughballs))
    const aerialDuels = uniqueVals(mdata.filter(d => d.node.aerialDuels && d.node.aerialDuels !== '-').map(d => d.node.aerialDuels))
    const bigChancesCreated = uniqueVals(mdata.filter(d => d.node.bigChancesCreated && d.node.bigChancesCreated !== '-').map(d => d.node.bigChancesCreated))
    const opponents = uniqueVals(mdata.map(d => d.node.opponent))
    const days = uniqueVals(mdata.map(d => d.node.date))

    const dateFiltersActive = this.state.dateFromDayFilter && this.state.dateFromMonthFilter && this.state.dateFromYearFilter && this.state.dateToDayFilter && this.state.dateToMonthFilter && this.state.dateToYearFilter ? true : false

    mdata = this.state.compFilter === 'All League' ? mdata.filter(d => d.node.competition === "La Liga" || d.node.competition === "Ligue 1" || d.node.competition === "MLS") : this.state.compFilter === 'All Competitive Internationals' ? mdata.filter(d => d.node.competition === "Copa America" || d.node.competition === "World Cup Qualifier" || d.node.competition === "World Cup" || d.node.competition === "Finalissima") : this.state.compFilter ? this.props.data.allSheetMessiMatchHistory.edges.filter(d => d.node.competition === this.state.compFilter) : this.props.data.allSheetMessiMatchHistory.edges

    mdata = this.state.seasonFilter ? mdata.filter(d => d.node.season === this.state.seasonFilter) : mdata
    mdata = this.state.yearFilter ? mdata.filter(d => d.node.year === this.state.yearFilter) : mdata
    mdata = this.state.teamFilter ? mdata.filter(d => d.node.team === this.state.teamFilter) : mdata
    mdata = this.state.goalsFilter ? mdata.filter(d => this.state.goalsFilter === "0=" ? Number(d.node.goals) === 0 : Number(d.node.goals) >= Number(this.state.goalsFilter)) : mdata
    mdata = this.state.assistsFilter ? mdata.filter(d => this.state.assistsFilter === "0=" ? Number(d.node.assists) === 0 : Number(d.node.assists) >= Number(this.state.assistsFilter)) : mdata
    mdata = this.state.pensFilter ? mdata.filter(d => this.state.pensFilter === "0=" ? Number(d.node.pens) === 0 : Number(d.node.pens) >= Number(this.state.pensFilter)) : mdata
    mdata = this.state.pensMissedFilter ? mdata.filter(d => Number(d.node.pensMissed) >= Number(this.state.pensMissedFilter)) : mdata
    mdata = this.state.freeKicksFilter ? mdata.filter(d => Number(d.node.freeKicks) >= Number(this.state.freeKicksFilter)) : mdata
    mdata = this.state.insideBoxFilter ? mdata.filter(d => Number(d.node.insideBox) >= Number(this.state.insideBoxFilter)) : mdata
    mdata = this.state.outsideBoxFilter ? mdata.filter(d => Number(d.node.outsideBox) >= Number(this.state.outsideBoxFilter)) : mdata
    mdata = this.state.leftFootFilter ? mdata.filter(d => Number(d.node.left) >= Number(this.state.leftFootFilter)) : mdata
    mdata = this.state.rightFootFilter ? mdata.filter(d => Number(d.node.right) >= Number(this.state.rightFootFilter)) : mdata
    mdata = this.state.headersFilter ? mdata.filter(d => Number(d.node.head) >= Number(this.state.headersFilter)) : mdata
    mdata = this.state.otherFilter ? mdata.filter(d => Number(d.node.other) >= Number(this.state.otherFilter)) : mdata
    mdata = this.state.shotsFilter ? mdata.filter(d => (d.node.shots && d.node.shots !== '-') && Number(d.node.shots) >= Number(this.state.shotsFilter)) : mdata
    mdata = this.state.shotsOnTargetFilter ? mdata.filter(d => (d.node.shotsOnTarget && d.node.shotsOnTarget !== '-') && Number(d.node.shotsOnTarget) >= Number(this.state.shotsOnTargetFilter)) : mdata
    mdata = this.state.successfulDribblesFilter ? mdata.filter(d => (d.node.successfulDribbles && d.node.successfulDribbles !== '-') && Number(d.node.successfulDribbles) >= Number(this.state.successfulDribblesFilter)) : mdata
    mdata = this.state.keyPassesFilter ? mdata.filter(d => (d.node.keyPasses && d.node.keyPasses !== '-') && Number(d.node.keyPasses) >= Number(this.state.keyPassesFilter)) : mdata
    mdata = this.state.throughballsFilter ? mdata.filter(d => (d.node.throughballs && d.node.throughballs !== '-') && Number(d.node.throughballs) >= Number(this.state.throughballsFilter)) : mdata
    mdata = this.state.aerialDuelsFilter ? mdata.filter(d => (d.node.aerialDuels && d.node.aerialDuels !== '-') && Number(d.node.aerialDuels) >= Number(this.state.aerialDuelsFilter)) : mdata
    mdata = this.state.bigChancesCreatedFilter ? mdata.filter(d => (d.node.bigChancesCreated && d.node.bigChancesCreated !== '-') && Number(d.node.bigChancesCreated) >= Number(this.state.bigChancesCreatedFilter)) : mdata
    mdata = this.state.xGFilter ? mdata.filter(d => (d.node.xg && d.node.xg !== '-') && Number(d.node.xg) >= Number(this.state.xGFilter)) : mdata
    mdata = this.state.motmFilter ? mdata.filter(d => d.node.motm === this.state.motmFilter) : mdata
    mdata = this.state.ratingFilter.includes('<') ? mdata.filter(d => (d.node.rating && d.node.rating !== '-') && Number(d.node.rating) < Number(this.state.ratingFilter.replace('< ',''))) : this.state.ratingFilter ? mdata.filter(d => (d.node.rating && d.node.rating !== '-') && Number(d.node.rating) >= Number(this.state.ratingFilter)) : mdata
    mdata = this.state.homeAwayFilter ? mdata.filter(d => d.node.homeAway === this.state.homeAwayFilter) : mdata
    mdata = this.state.opponentFilter ? mdata.filter(d => d.node.opponent === this.state.opponentFilter) : mdata
    mdata = this.state.roundFilter === "All Knockout Rounds" ? mdata.filter(d => d.node.round === "F" || d.node.round === "SF" || d.node.round === "QF" || d.node.round === "R16" || d.node.round === "R32" || d.node.round === "R64" || d.node.round === "R128" || d.node.round === "Play-off" || d.node.round === "3rd Place Play-off") : this.state.roundFilter ? mdata.filter(d => d.node.round === this.state.roundFilter) : mdata
    mdata = this.state.legsFilter ? mdata.filter(d => d.node.notes && d.node.notes.includes(this.state.legsFilter)) : mdata
    mdata = this.state.vsPlayerFilter ? mdata.filter(d => d.node.h2H === '1') : mdata
    mdata = this.state.rankingFromFilter || this.state.rankingToFilter ? mdata.filter(d => (d.node.team === "Argentina") && (d.node.opponentLeaguePosition && d.node.opponentLeaguePosition !== '-') && Number(d.node.opponentLeaguePosition) >= Number(this.state.rankingFromFilter) && Number(d.node.opponentLeaguePosition) <= Number(this.state.rankingToFilter)) : mdata
    mdata = this.state.uclRankingFromFilter || this.state.uclRankingToFilter ? mdata.filter(d => (d.node.competition === "Champions League") && (d.node.opponentLeaguePosition && d.node.opponentLeaguePosition !== '-') && Number(d.node.opponentLeaguePosition) >= Number(this.state.uclRankingFromFilter) && Number(d.node.opponentLeaguePosition) <= Number(this.state.uclRankingToFilter)) : mdata
    mdata = this.state.leagueRankingFromFilter || this.state.leagueRankingToFilter ? mdata.filter(d => (d.node.opponentLeaguePosition && d.node.opponentLeaguePosition !== '-') && Number(d.node.opponentLeaguePosition) >= Number(this.state.leagueRankingFromFilter) && Number(d.node.opponentLeaguePosition) <= Number(this.state.leagueRankingToFilter)) : mdata
    mdata = this.state.dayFilter ? mdata.filter(d => d.node.date === this.state.dayFilter) : mdata

    mdata = dateFiltersActive ? mdata.filter(d => (d.node.year+d.node.date.split('-')[1]+d.node.date.split('-')[0]) >= this.state.dateFromYearFilter+this.state.dateFromMonthFilter+this.state.dateFromDayFilter && (d.node.year+d.node.date.split('-')[1]+d.node.date.split('-')[0]) <= this.state.dateToYearFilter+this.state.dateToMonthFilter+this.state.dateToDayFilter ) : mdata

    mdata = this.state.firstXFilter ? mdata.slice(Math.max(mdata.length - this.state.firstXFilter, 0)) : mdata

    mdata = this.state.startsFilter === "starts" ? mdata.filter(d => d.node.started === "1") : this.state.startsFilter === "sub" ? mdata.filter(d => d.node.started === "0") : this.state.startsFilter === "full" ? mdata.filter(d => Number(d.node.minsPlayed) >= 90) : mdata

    let totalGames = findTotal(mdata)

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');

    return (
      <Layout>
        <SEO
          title={"Lionel Messi Complete Match History Database"}
          description={`A full list of every single game of Lionel Messi's career. Every goal, assist, hat-trick, free kick, penalty, dribble, key pass, shot on target, and more!`}
          canonicalPath={`/match-histories/messi-match-history/`}
        />

        <h1>Complete Messi Match History</h1>

        

        <div className={`filters mt-12 md:mt-20 max-w-md mx-auto`}>

          <div className="flex flex-wrap justify-center p-1 pt-2 md:p-2 md:pt-3 border border-gray-700">

            <div className="w-full text-center -mt-5 md:-mt-6 mb-1 md:mb-2">
              <p className="inline-block uppercase px-3 text-sm tracking-wider bg-noir text-gray-600">Match Filters</p>
            </div>

            <Filter
              label="Teams"
              firstOption="All Teams"
              array={teams}
              onChangeFunc={this.manageTeamFilter}
              value={this.state.teamFilter}
              width="w-1/2 md:w-1/3"
              type="main"
            />

            <Filter
              label="Competitions"
              firstOption="All Competitions"
              array={competitions}
              additionalFields={['All League']}
              onChangeFunc={this.manageCompFilter}
              value={this.state.compFilter}
              width="w-1/2 md:w-1/3"
              type="main"
            />

            <Filter
              label="Seasons"
              firstOption="All Seasons"
              array={seasons}
              onChangeFunc={this.manageSeasonFilter}
              value={this.state.seasonFilter}
              width="w-1/2 md:w-1/3"
              type="main"
            />

            <Filter
              label="Years"
              firstOption="All Years"
              array={years}
              onChangeFunc={this.manageYearFilter}
              value={this.state.yearFilter}
              width="w-1/2 md:w-1/3"
              type="main"
            />

            <Filter
              label="Goals"
              additionalFields={[{"text":"0 goals","value":'0='}]}
              array={goals.sort()}
              onChangeFunc={this.manageGoalsFilter}
              value={this.state.goalsFilter}
              extraOptionText="+ goals"
              width="w-1/2 md:w-1/3"
              type="main"
            />

            <Filter
              label="Assists"
              additionalFields={[{"text":"0 assists","value":'0='}]}
              array={assists.sort()}
              onChangeFunc={this.manageAssistsFilter}
              value={this.state.assistsFilter}
              extraOptionText="+ assists"
              width="w-1/2 md:w-1/3"
              type="main"
            />
          </div>


          {/* EXTRA FILTER / RESET TOGGLES */}

          <div className="flex w-full justify-between mt-2 md:mt-4">
            <button
              onClick={this.toggleMoreFilters}
              className="w-auto p-1 bg-gray-900 hover:bg-highlight focus:bg-highlight transition-300 text-gray-600 hover:text-noir focus:text-noir tracking-widest text-center uppercase text-xs focus:outline-none font-semibold"
            >
              {this.state.moreFiltersActive ? (
                <>
                  <Icon iconName="icon-angle-up" class="text-gray-600 text-xs mr-1" />
                  <span>Hide Filters</span>
                  <Icon iconName="icon-angle-up" class="text-gray-600 text-xs ml-1" />
                </>
              ) : (
                <>
                  <Icon iconName="icon-angle-down" class="text-gray-600 text-xs mr-1" />
                  <span>More Filters</span>
                  <Icon iconName="icon-angle-down" class="text-gray-600 text-xs ml-1" />
                </>
              )}
            </button>

            <button onClick={this.resetFilters} className="w-auto block text-center text-sm text-gray-600 hover:text-highlight-darken10 focus:outline-none focus:text-highlight-darken20 mr-2"><Icon iconName="icon-redo" class="text-xs mr-1" /> Reset filters</button>
          </div>


          {/* GOAL FILTERS */}

          <div className={`mt-8 flex-wrap justify-center p-1 pt-2 md:p-2 md:pt-3 border border-gray-700 ${this.state.moreFiltersActive ? 'flex' : 'hidden'}`}>

            <div className="w-full text-center -mt-5 md:-mt-6 mb-1 md:mb-2">
              <p className="inline-block uppercase px-3 text-sm tracking-wider bg-noir text-gray-600">Goal Filters</p>
            </div>

            <Filter
              label="Pens Scored"
              additionalFields={[{"text":"0 pens scored","value":'0='}]}
              array={[0,1,2,3]}
              onChangeFunc={this.managePensFilter}
              value={this.state.pensFilter}
              extraOptionText="+ pens scored"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Pens Missed"
              array={[0,1,2,3]}
              onChangeFunc={this.managePensMissedFilter}
              value={this.state.pensMissedFilter}
              extraOptionText="+ pens missed"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Free Kick Goals"
              array={[0,1,2,3]}
              onChangeFunc={this.manageFreeKicksFilter}
              value={this.state.freeKicksFilter}
              extraOptionText="+ free kick goals"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Inside Box Goals"
              array={[0,1,2,3,4,5]}
              onChangeFunc={this.manageInsideBoxFilter}
              value={this.state.insideBoxFilter}
              extraOptionText="+ inside box goals"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Outside Box Goals"
              array={[0,1,2,3,4,5]}
              onChangeFunc={this.manageOutsideBoxFilter}
              value={this.state.outsideBoxFilter}
              extraOptionText="+ outside box goals"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Left Foot Goals"
              array={[0,1,2,3,4,5]}
              onChangeFunc={this.manageLeftFootFilter}
              value={this.state.leftFootFilter}
              extraOptionText="+ left foot goals"
              width="w-1/2 md:w-1/4"
            />

            <Filter
              label="Right Foot Goals"
              array={[0,1,2,3,4,5]}
              onChangeFunc={this.manageRightFootFilter}
              value={this.state.rightFootFilter}
              extraOptionText="+ right foot goals"
              width="w-1/2 md:w-1/4"
            />

            <Filter
              label="Headed Goals"
              array={[0,1,2,3,4,5]}
              onChangeFunc={this.manageHeadersFilter}
              value={this.state.headersFilter}
              extraOptionText="+ headed goals"
              width="w-1/2 md:w-1/4"
            />

            <Filter
              label="Other Body Part"
              array={[0,1,2,3,4,5]}
              onChangeFunc={this.manageOtherFilter}
              value={this.state.otherFilter}
              extraOptionText="+ other goals"
              width="w-1/2 md:w-1/4"
            />
          </div>


          {/* PERFORMANCE FILTERS */}

          <div className={`mt-8 flex-wrap justify-center p-1 pt-2 md:p-2 md:pt-3 border border-gray-700 ${this.state.moreFiltersActive ? 'flex' : 'hidden'}`}>

            <div className="w-full text-center -mt-5 md:-mt-6 mb-1 md:mb-2">
              <p className="inline-block uppercase px-3 text-sm tracking-wider bg-noir text-gray-600">Performance Filters</p>
              <p className="text-xs text-gray-600">Performance stats only available for league & Champions League games since 2009/10 + some other games</p>
            </div>

            <Filter
              label="Shots"
              array={shots.sort((a,b) => a-b)}
              onChangeFunc={this.manageShotsFilter}
              value={this.state.shotsFilter}
              extraOptionText="+ shots"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Shots on Target"
              array={shotsOnTarget.sort((a,b) => a-b)}
              onChangeFunc={this.manageShotsOnTargetFilter}
              value={this.state.shotsOnTargetFilter}
              extraOptionText="+ shots on target"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Successful Dribbles"
              array={successfulDribbles.sort((a,b) => a-b)}
              onChangeFunc={this.manageSuccessfulDribblesFilter}
              value={this.state.successfulDribblesFilter}
              extraOptionText="+ successful dribbles"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Key Passes"
              array={keyPasses.sort((a,b) => a-b)}
              onChangeFunc={this.manageKeyPassesFilter}
              value={this.state.keyPassesFilter}
              extraOptionText="+ key passes"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Big Chances Created"
              array={bigChancesCreated.sort((a,b) => a-b)}
              onChangeFunc={this.manageBigChancesCreatedFilter}
              value={this.state.bigChancesCreatedFilter}
              extraOptionText="+ big chances created"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Accurate Througballs"
              array={throughballs.sort((a,b) => a-b)}
              onChangeFunc={this.manageThroughballsFilter}
              value={this.state.throughballsFilter}
              extraOptionText="+ accurate throughballs"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Aerial Duels"
              array={aerialDuels.sort((a,b) => a-b)}
              onChangeFunc={this.manageAerialDuelsFilter}
              value={this.state.aerialDuelsFilter}
              extraOptionText="+ aerial duels"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Expected Goals"
              array={['0.00','0.10','0.20','0.30','0.40','0.50','0.60','0.70','0.80','0.90','1.00','1.10','1.20','1.30','1.40','1.50','1.60','1.70','1.80','1.90','2.00','2.10','2.20','2.30','2.40','2.50','2.60','2.70','2.80','2.90','3.00']}
              onChangeFunc={this.manageXGFilter}
              value={this.state.xGFilter}
              extraOptionText="+ xG"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Man of the Match"
              array={[{"text":"Yes","value":'1'},{"text":"No","value":'0'}]}
              onChangeFunc={this.manageMotmFilter}
              value={this.state.motmFilter}
              extraOptionText=" (MOTM)"
              width="w-1/2 md:w-1/5"
            />

            <Filter
              label="Match Rating"
              array={['10.0','9.50','9.00','8.50','8.00','7.50','7.00','6.50','6.00','5.50','5.00','< 9.00','< 8.50','< 8.00','< 7.50','< 7.00','< 6.50','< 6.00','< 5.50']}
              onChangeFunc={this.manageRatingFilter}
              value={this.state.ratingFilter}
              extraOptionText="+ rating"
              width="w-1/2 md:w-1/5"
            />

          </div>
          

          {/* MORE FILTERS */}

          <div className={`mt-8 flex-wrap justify-center p-1 pt-2 md:p-2 md:pt-3 border border-gray-700 ${this.state.moreFiltersActive ? 'flex' : 'hidden'}`}>

            <div className="w-full text-center -mt-5 md:-mt-6 mb-1 md:mb-2">
              <p className="inline-block uppercase px-3 text-sm tracking-wider bg-noir text-gray-600">More Filters</p>
            </div>

            <Filter
              label="Home/Away"
              array={[{"text":"Home Games","value":'H'},{"text":"Away Games","value":'A'},{"text":"Neutral Games","value":'N'}]}
              onChangeFunc={this.manageHomeAwayFilter}
              value={this.state.homeAwayFilter}
              width="w-1/2 md:w-1/6"
            />

            <Filter
              label="Round"
              firstOption="All Rounds"
              array={['F','SF','QF','R16','R32','R64','R96','R128','All Knockout Rounds','Group','3rd Place Play-off','Play-off']}
              onChangeFunc={this.manageRoundFilter}
              value={this.state.roundFilter}
              width="w-1/2 md:w-1/6"
            />

            <Filter
              label="Legs"
              firstOption="All Legs"
              array={[{"text":"1st Legs","value":'leg1'},{"text":"2nd Legs","value":'leg2'}]}
              onChangeFunc={this.manageLegsFilter}
              value={this.state.legsFilter}
              width="w-1/2 md:w-1/12"
            />

            <Filter
              label="Opponent"
              firstOption="All Opponents"
              array={opponents.sort()}
              onChangeFunc={this.manageOpponentFilter}
              value={this.state.opponentFilter}
              width="w-1/2 md:w-1/5"
            />

            <div className="flex flex-auto p-1 text-xs w-1/2 md:w-1/5" onChange={this.manageVsPlayerFilter}>
              <label className={`flex w-full items-center p-2 cursor-pointer border ${this.state.vsPlayerFilter ? 'border-highlight' : 'border-gray-500'}`}>
                <input type="checkbox" checked={this.state.vsPlayerFilter ? true : false} />
                <span className="ml-2">Only games vs Ronaldo</span>
              </label>
            </div>

            <div className="w-full">

              <div className="flex flex-col md:flex-row md:items-center">

                <Filter
                  label="Show all appearances"
                  array={[{"text":"Only games started","value":'starts'},{"text":"Sub appearances","value":'sub'},{"text":"Full 90+ mins","value":'full'}]}
                  onChangeFunc={this.manageStartsFilter}
                  value={this.state.startsFilter}
                  width="w-full md:w-1/4"
                />

                <Filter
                  label="Show first X no. of games"
                  array={Array(Math.floor((careerGamesTotal - 0) / 10) + 1).fill().map((_, idx) => 0 + (idx * 10))}
                  onChangeFunc={this.manageFirstXFilter}
                  value={this.state.firstXFilter}
                  extraOptionText=" games"
                  width="w-full md:w-1/4"
                />

                <div className="w-full md:w-1/2 flex flex-wrap md:flex-no-wrap items-center py-1 text-gray-600 text-sm">
                  { this.state.compFilter === "Champions League" ? (
                    
                    <>
                      <label className="w-full md:w-auto p-2 text-center md:text-right md:text-xs">Games vs teams with Coefficient ranking between:</label>

                      <Filter
                        label="0"
                        array={[1,2,3,4,5,10,11,15,16,20,21,25,26,30,35,36,40,41,45,46,50,51,55,60,61,65,70,71,75,80,81,85,90,91,95,100,101,110,120,130,140,150]}
                        onChangeFunc={this.manageUCLRankingFromFilter}
                        value={this.state.uclRankingFromFilter}
                        extraOptionText=""
                        width="w-2/5 md:w-28"
                      />

                      <span className="w-1/5 text-center md:text-xs">and</span>

                      <Filter
                        label="0"
                        array={[1,2,3,4,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100,110,120,130,140,150,200]}
                        onChangeFunc={this.manageUCLRankingToFilter}
                        value={this.state.uclRankingToFilter}
                        extraOptionText=""
                        width="w-2/5 md:w-28"
                      />

                    </>
                  ) : this.state.compFilter === "La Liga" || this.state.compFilter === "Ligue 1" || this.state.compFilter === "All League" ? (
                    
                    <>
                      <label className="w-full md:w-auto p-2 text-center md:text-right md:text-xs">Games vs teams with league position between:</label>

                      <Filter
                        label="0"
                        array={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]}
                        onChangeFunc={this.manageLeagueRankingFromFilter}
                        value={this.state.leagueRankingFromFilter}
                        extraOptionText=""
                        width="w-2/5 md:w-28"
                      />

                      <span className="w-1/5 text-center md:text-xs">and</span>

                      <Filter
                        label="0"
                        array={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]}
                        onChangeFunc={this.manageLeagueRankingToFilter}
                        value={this.state.leagueRankingToFilter}
                        extraOptionText=""
                        width="w-2/5 md:w-28"
                      />

                    </>
                  ) : (
                    <>

                      <label className="w-full md:w-auto p-2 text-center md:text-right md:text-xs">Games vs countries with FIFA ranking between:</label>

                      <Filter
                        label="0"
                        array={[3,5,10,11,15,16,20,21,25,26,30,35,36,40,41,45,46,50,51,55,60,61,65,70,71,75,80,81,85,90,91,95,100,101,110,120,130,140,150,200,250]}
                        onChangeFunc={this.manageRankingFromFilter}
                        value={this.state.rankingFromFilter}
                        extraOptionText=""
                        width="w-2/5 md:w-28"
                      />

                      <span className="w-1/5 text-center md:text-xs">and</span>

                      <Filter
                        label="0"
                        array={[3,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100,110,120,130,140,150,200,250]}
                        onChangeFunc={this.manageRankingToFilter}
                        value={this.state.rankingToFilter}
                        extraOptionText=""
                        width="w-2/5 md:w-28"
                      />

                    </>
                  ) }
                </div>

              </div>

              <div className="w-full flex flex-wrap md:flex-no-wrap items-center py-1 text-gray-600 text-sm">

                <label className="w-full md:w-27p p-2 text-center">Games between these dates:</label>
                
                <Filter
                  label="Day"
                  array={['01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]}
                  onChangeFunc={this.manageDateFromDayFilter}
                  value={this.state.dateFromDayFilter}
                  extraOptionText=""
                  width="w-4/12 sm:w-auto md:w-20"
                />

                <Filter
                  label="Month"
                  array={['01','02','03','04','05','06','07','08','09','10','11','12']}
                  onChangeFunc={this.manageDateFromMonthFilter}
                  value={this.state.dateFromMonthFilter}
                  extraOptionText=""
                  width="w-4/12 sm:w-auto md:w-20"
                />

                <Filter
                  label="Year"
                  array={[2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030]}
                  onChangeFunc={this.manageDateFromYearFilter}
                  value={this.state.dateFromYearFilter}
                  extraOptionText=""
                  width="w-4/12 sm:w-auto md:w-24"
                />

                <span className="w-full sm:w-auto sm:px-2 text-center">-</span>

                <Filter
                  label="Day"
                  array={['01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]}
                  onChangeFunc={this.manageDateToDayFilter}
                  value={this.state.dateToDayFilter}
                  extraOptionText=""
                  width="w-4/12 sm:w-auto md:w-20"
                />

                <Filter
                  label="Month"
                  array={['01','02','03','04','05','06','07','08','09','10','11','12']}
                  onChangeFunc={this.manageDateToMonthFilter}
                  value={this.state.dateToMonthFilter}
                  extraOptionText=""
                  width="w-4/12 sm:w-auto md:w-20"
                />

                <Filter
                  label="Year"
                  array={[2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030]}
                  onChangeFunc={this.manageDateToYearFilter}
                  value={this.state.dateToYearFilter}
                  extraOptionText=""
                  width="w-4/12 sm:w-auto md:w-24"
                />

              </div>

              <div className="w-full flex flex-wrap md:flex-no-wrap items-center py-1 text-gray-600 text-sm">
                <label className="w-full md:w-27p p-2 text-center">Games on specific date:</label>
                <Filter
                  label="Date"
                  firstOption="All Dates"
                  additionalFields={[{"text":"On This Day","value":dd+'-'+mm}]}
                  array={days.sort()}
                  onChangeFunc={this.manageDayFilter}
                  value={this.state.dayFilter}
                  width="w-1/2 md:w-auto"
                />
              </div>

            </div>

          </div>

        </div>


        <MatchTotals
          data={mdata.filter(d => d.node.date)}
          internationalsActive={this.state.rankingFromFilter || this.state.rankingToFilter || this.state.teamFilter === "Argentina"}
          uclActive={this.state.uclRankingFromFilter || this.state.uclRankingToFilter || this.state.compFilter === "Champions League"}
          player="Messi"
        />

        <button onClick={this.toggleRatingsInfoModal} className="mt-8 max-w-md mx-auto text-xs text-center flex justify-center items-center">
          <span className="text-gray-600 px-2 py-1 bg-noir-lighten5 hover:bg-noir-lighten10"><Icon iconName="icon-info-circle" class="text-gray-600 text-xs mr-1" /> Information on ratings and stats</span>
        </button>
        
        <ModalStandard handler={this.toggleRatingsInfoModal} active={this.state.ratingsInfoModalActive}>
          <p>The match player ratings use the <a className="underline" href="https://www.whoscored.com/Explanations">WhoScored</a> rating algorithm, which is considered the most respected and accurate rating system in football. The algorithm is their own, but the stats the rating is based on are sourced straight from Opta.</p>

          <p>Opta review their stats after each game, so the rating is also subject to change for a day or so after the match.</p>

          <p>For matches that WhoScored does not cover, the <a className="underline" href="https://www.sofascore.com/news/sofascore-player-ratings/">SofaScore</a> rating algorithm and stats are used instead. These games are marked with an asterisk (*).</p>

          <p>Again, SofaScore is powered by Opta stats - the only difference being that their key pass stats also include Opta's "Second Assist" in addition to passes that lead to shots. The definition for this is "a pass that creates an opportunity for another player to assist a goal", however this is a relatively rare occurrence.</p>
        </ModalStandard>

        <div className={`${styles.matchListWrap} mt-6 md:mt-8 max-w-md mx-auto`}>
          {mdata.filter(d => d.node.date).map((d, index) => (
            <Match
              key={d.node.date+d.node.year}
              index={totalGames-index}
              data={d.node}
              player="Messi"
            />
          ))}
        </div>

        <LastUpdated type="International Stats" />
        
      </Layout>
    )
  }
}
  

export const query = graphql`
  query {
    allSheetMessiMatchHistory {
      edges {
        node {
          date
          year
          season
          competition
          homeAway
          round
          team
          opponent
          scoreTeam
          scoreOpponent
          goals
          assists
          started
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          rating
          freeKickAttempts
          bigChancesCreated
          xg
          xa
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          allAssists
          ftScore
          penScore
          shootout
          h2H
          opponentLeaguePosition
          notes
        }
      }
    }
  }
`

export default MessiMatchHistory
